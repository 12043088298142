import { Injectable } from '@angular/core';
import {FirebaseService} from "../firebase/firebase.service";
import {from, map, Observable} from "rxjs";
import {collection, doc, getDoc, QueryDocumentSnapshot, DocumentSnapshot, setDoc} from "firebase/firestore";
import {deleteDoc, getDocs} from "@angular/fire/firestore";
import {User} from "@eeule/eeule-shared";

@Injectable({
  providedIn: 'root'
})
/**
 * Service to interact with user data in Firebase Firestore.
 */
export class UserService {

  constructor(private _firebaseService: FirebaseService) { }

  /**
   * Sets user data in Firestore.
   * @param {User} user - The user object to be set.
   * @returns {Observable<void>} An observable that completes when the operation is done.
   */
  setUser(user: User): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `users`, user.id);
    return from(setDoc(docRef, user));
  }

  /**
   * Retrieves all users from Firestore.
   * @returns {Observable<QueryDocumentSnapshot[]>} An observable emitting an array of query document snapshots.
   */
  getAllUsers(): Observable<QueryDocumentSnapshot[]> {
    const docRef = collection(this._firebaseService.firestore, `users`);
    return from(getDocs(docRef)).pipe(map((snapshot) => snapshot.docs));
  }

  /**
   * Retrieves a specific user from Firestore.
   * @param {string} id - The ID of the user to retrieve.
   * @returns {Observable<DocumentSnapshot>} An observable emitting the document snapshot of the user.
   */
  getUser(id: string): Observable<DocumentSnapshot> {
    const docRef = doc(this._firebaseService.firestore, `users`, id);
    return from(getDoc(docRef));
  }

  /**
   * Deletes a user from Firestore.
   * @param {string} id - The ID of the user to delete.
   * @returns {Observable<void>} An observable that completes when the operation is done.
   */
  deleteUser(id: string): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `users`, id);
    return from(deleteDoc(docRef));
  }
}
